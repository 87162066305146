<template>
  <div class="home">
      
      <!-- Active Competitions -->

      <section class="container">
            <h1 class="text-center mt-5 mb-5">404 Page Not Found</h1>
      </section>

  </div>

  <Footer />

</template>

<script>

import Footer from '../components/global/Footer'

export default {
    components: {
        Footer
    }
}
</script>
